import { HAS_PAID_DEPOSIT } from "../../constants/network-calls";
import { DepositInformation, StripeChargeResponse } from "./payment.type";
import { getApiClient, getFondoApi } from "../../utils/util.api";


export async function getDepositPaid(): Promise<DepositInformation> {
  const result = await getFondoApi(HAS_PAID_DEPOSIT);
  return result as DepositInformation;
}

export async function postStripeCharge(): Promise<StripeChargeResponse> {
    const apiClient = await getApiClient();
    const result = await apiClient.createReactifyPaymentStripeCharge();
    return result.data;
  }
