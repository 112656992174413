import { CardGroup } from "react-bootstrap";
import CorporateTaxSummaryCard from "./CorporateTaxSummaryCard";
import confettiEmoji from "../../../assets/img/confetti-emoji.svg";
import confettiEmojiDisabled from "../../../assets/img/confetti-emoji-disabled.svg";
import taxableIncome from "../../../assets/img/taxable-income.svg";
import taxesOwed from "../../../assets/img/taxes-owed.svg";
import { TaxPlanResult } from "../financeDashboard.type";
import {
  rDCreditEstimateTooltipContent,
  taxableIncomeEstimateTooltipContent,
  taxesOwedEstimateTooltipContent,
} from "./utils";
import { getRDCreditEstimateAmount, getTaxableIncomeEstimate } from "./formulas";
import { formatCurrency } from "../../../utils/common.util";

type CorporateTaxSummaryCardsRowProps = {
  taxPlanResultData?: TaxPlanResult;
  taxPlanResultDataIsLoading: boolean;
}

const CorporateTaxSummaryCardsRow = ({
  taxPlanResultData,
  taxPlanResultDataIsLoading,
}: CorporateTaxSummaryCardsRowProps) => (
  <CardGroup style={ { gap: "1rem" } } className="mb-4">
    <CorporateTaxSummaryCard
      title="R&D Credit (estimate)"
      value={formatCurrency(
        getRDCreditEstimateAmount(taxPlanResultData)
      )}
      valueColorClassName="success"
      icon={confettiEmoji}
      disabledIcon={confettiEmojiDisabled}
      isLoading={taxPlanResultDataIsLoading}
      disabled={!taxPlanResultData}
      showHelpTooltip
      helpTooltipHTML={rDCreditEstimateTooltipContent}
    />
    <CorporateTaxSummaryCard
      title="Taxable Income (estimate)"
      value={formatCurrency(getTaxableIncomeEstimate(taxPlanResultData))}
      valueColorClassName="primary"
      icon={taxableIncome}
      isLoading={taxPlanResultDataIsLoading}
      disabled={!taxPlanResultData}
      showHelpTooltip
      helpTooltipHTML={taxableIncomeEstimateTooltipContent}
    />
    <CorporateTaxSummaryCard
      title="Taxes Owed (estimate)"
      value={formatCurrency(taxPlanResultData?.taxesOwedEstimateAmount || 0)}
      valueColorClassName="danger"
      icon={taxesOwed}
      isLoading={taxPlanResultDataIsLoading}
      disabled={!taxPlanResultData}
      showHelpTooltip
      helpTooltipHTML={taxesOwedEstimateTooltipContent}
    />
  </CardGroup>
);

export default CorporateTaxSummaryCardsRow;
