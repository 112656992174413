import FeatherIcon from "feather-icons-react";
import React from "react";
import { Dropdown } from "react-bootstrap";

type PartnerCardTopBarProps = {
  badge?: React.ReactNode;
  dropdownItems?: JSX.Element[] | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
};

export const PartnerCardTopBar = ({ badge, dropdownItems, className, style }: PartnerCardTopBarProps) => {
  let dropdownMenu = null;
  if (Array.isArray(dropdownItems)) {
    dropdownMenu = (
      <Dropdown>
        <Dropdown.Toggle variant="">
          <FeatherIcon icon="more-vertical" size={24} color="#6E84A3" className="cursor-pointer" />
        </Dropdown.Toggle>

        <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
      </Dropdown>
    );
  } else if (dropdownItems) {
    dropdownMenu = dropdownItems;
  }
  const finalStyle = { ...style, position: "absolute", width: "100%" } as React.CSSProperties;
  return (
    <div className={`d-flex justify-content-between ${className ?? "px-1 pt-2"}`} style={finalStyle}>
      {badge || <div />}
      {dropdownMenu || <div />}
    </div>
  );
};
