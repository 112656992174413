import { useRef } from "react";
import { ConnectionType } from "../../asyncSelectors/AsyncExternalConnectionTypeSelect";
import { Partner } from "../passport-partners.types";
import { PartnerFilterButton } from "./PartnerFilterButton";

const filterIdToIcon: Record<string, string> = {
  isTop10: "award",
  mostReviewed: "message-circle",
  highestRated: "star",
  oneClickPartners: "zap",
  hasDeal: "dollar-sign",
};

const filterIdToFilter: Record<string, (partners: Partner[]) => Partner[]> = {
  isTop10: (partners) => partners.filter((partner) => partner.isTop10).slice(0, 10),
  mostReviewed: (partners) => partners.sort((a, b) => b.numReviews - a.numReviews).slice(0, 10),
  highestRated: (partners) => partners.sort((a, b) => (b.averageScore ?? 0) - (a.averageScore ?? 0)).slice(0, 10),
  oneClickPartners: (partners) => partners.filter((partner) => partner.hasOneTouchDeal),
  hasDeal: (partners) =>
    partners.filter((partner) => partner.products.length > 0 && partner.products[0].deals.length > 0),
};

const filterIdToTitle: Record<string, string> = {
  isTop10: "Top Ten",
  mostReviewed: "Most Reviewed",
  highestRated: "Highest Rated",
  oneClickPartners: "One Click Partners",
  hasDeal: "Has Deal",
};

type PartnerFilterBarProps = {
  idToConnectionTypesMap: Record<number, ConnectionType>;
  partners: Partner[];
  setPartnersToDisplay: (partners: Partner[]) => void;
};

export const PartnerFilterBar = ({ idToConnectionTypesMap, partners, setPartnersToDisplay }: PartnerFilterBarProps) => {
  // apply filters in the order they were enabled
  const enabledFiltersSetRef = useRef(new Set<string>());

  const handleFilterChange = (changedFilterId: string) => {
    if (enabledFiltersSetRef.current.has(changedFilterId)) {
      enabledFiltersSetRef.current.delete(changedFilterId);
    } else {
      enabledFiltersSetRef.current.add(changedFilterId);
    }

    let filteredPartners = [...partners];
    enabledFiltersSetRef.current.forEach((filterId) => {
      if (filterId in filterIdToFilter) {
        filteredPartners = filterIdToFilter[filterId](filteredPartners);
      } else {
        const connectionTypeId = parseInt(filterId, 10);
        filteredPartners = filteredPartners.filter((partner) =>
          partner.connectionTypeIds.some((id) => id === connectionTypeId),
        );
      }
    });
    setPartnersToDisplay(filteredPartners);
  };

  return (
    <div className="d-flex flex-wrap mb-3 w-100">
      {[...Object.keys(filterIdToTitle), ...Object.keys(idToConnectionTypesMap)].map((filterId) => (
        <PartnerFilterButton
          key={filterId}
          filterEnabled={enabledFiltersSetRef.current.has(filterId)}
          setFilterEnabled={() => handleFilterChange(filterId)}
          icon={filterIdToIcon[filterId]}
          color={idToConnectionTypesMap[filterId as unknown as number]?.badgeColor}
          text={filterIdToTitle[filterId] ?? idToConnectionTypesMap[filterId as unknown as number]?.name}
        />
      ))}
    </div>
  );
};
