import {
  Button, ListGroup, Modal, Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TimeRangeIntervalSelector from "../../finance-dashboard/TimeRangeIntervalSelector";
import { DateRangeIntervals, TimeRange } from "../../finance-dashboard/financeDashboard.type";
import CreateNewReportRow from "./CreateNewReportRow";
import { createNewReportingInfo } from "../Reporting.api";
import { ReportingSubmission } from "../Reporting.type";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import { getLastDayOfMonth } from "../../../utils/common.util";
import { mapMonthToMonthDate, mapQuarterToMonthDate } from "../../finance-dashboard/constants";
import reportingExecutiveSummary from "../../../assets/img/icons/reportingExecutiveSummary.svg";
import reportingRunway from "../../../assets/img/icons/reportingRunway.svg";
import reportingProfitAndLoss from "../../../assets/img/icons/reportingProfitAndLoss.svg";
import reportingBalanceSheet from "../../../assets/img/icons/reportingBalanceSheet.svg";
import { missingQBOSections, missingBankSections } from "../utils";
import { amountOfConnectedAccounts, getSourceLinkedAccounts } from "../../integrations/integrations.api";

type CreateNewReportModalProps = {
  show: boolean;
  handleClose: () => void;
  balanceAvailableIntervals?: DateRangeIntervals;
  handleApplyInterval: (data: TimeRange) => void;
  currentTimeRange: TimeRange;
}

const CreateNewReportModal = ({
  show,
  handleClose,
  balanceAvailableIntervals,
  handleApplyInterval,
  currentTimeRange,
}: CreateNewReportModalProps) => {
  const [reportName, setReportName] = useState("");
  const [showTimeRangeModal, setShowTimeRangeModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    executiveSummary: false,
    runway: false,
    profitAndLoss: false,
    balanceSheet: false,
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentLoggedInUserContext = React.useContext(LoggedInUserContext);

  const createNewReportMutation = useMutation(
    createNewReportingInfo,
    {
      onSuccess: (report) => {
        queryClient.invalidateQueries({queryKey: ["reportings"]});
        closeModal();
        navigate(`/dashboard/reporting/${report.uuid}/`);
    },
    },
  );

  const { data: numBankAccounts } = useQuery<any, Error>(["amountOfConnectedAccounts"], amountOfConnectedAccounts, {
    refetchOnWindowFocus: false
  });

  const missingBankConnection = numBankAccounts === 0 && (selectedReport.executiveSummary || selectedReport.runway);

  const category = "QBO";

  const { data: linkedAccountsQueryData } = useQuery<any, Error>(
    ["linkedAccountData", category],
    () => getSourceLinkedAccounts(category),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const missingQBOConnection = () => {
    if (selectedReport.profitAndLoss || selectedReport.balanceSheet) {
        const linkedAccounts = linkedAccountsQueryData || [];
        return linkedAccounts.length === 0;
    }
    return false;
  };

  const determineStartAndEndDates = () => {
    if (currentTimeRange.interval === "year") {
      return [`${currentTimeRange.year}-01-01`, `${currentTimeRange.year}-12-31`];
    }
    if (currentTimeRange.interval === "month") {
      const startDate = `${currentTimeRange.year}-${mapMonthToMonthDate[currentTimeRange.timePeriod || ""]}-01`;
      const endDate = getLastDayOfMonth(
        Number(currentTimeRange.year),
        Number(mapMonthToMonthDate[currentTimeRange.timePeriod || ""]),
      );
      return [startDate, endDate];
    }
    // Get the quarter as an integer and subtract 2 to get us the start month for the quarter
    const startQuarterInt = Number(mapQuarterToMonthDate[currentTimeRange.timePeriod || ""]) - 2;
    // Pad zeros if a single digit
    const startQuarter = startQuarterInt.toString().padStart(2, "0");
    const startDate = `${currentTimeRange.year}-${startQuarter}-01`;
    const endDate = getLastDayOfMonth(
      Number(currentTimeRange.year),
      Number(mapQuarterToMonthDate[currentTimeRange.timePeriod || ""]),
    );
    return [startDate, endDate];
  };

  const createNewReport = () => {
    const [timeframeStart, timeframeEnd] = determineStartAndEndDates();
    const submissionData: ReportingSubmission = {
      name: reportName,
      executiveSummary: selectedReport.executiveSummary,
      runway: selectedReport.runway,
      profitAndLoss: selectedReport.profitAndLoss,
      balanceSheet: selectedReport.balanceSheet,
      timeframeStart,
      timeframeEnd,
      groupedBy: currentTimeRange.interval.toUpperCase(),
      createdBy: currentLoggedInUserContext.currentLoggedInUserId || 0,
    };

    createNewReportMutation.mutate(submissionData);
  };

  const closeModal = () => {
    // Clean dirty fields
    setReportName("");
    setSelectedReport({
      executiveSummary: false,
      runway: false,
      profitAndLoss: false,
      balanceSheet: false,
    });
    handleClose();
  };

  const closeTimeRangeModel = (data: TimeRange) => {
    // Used to close the nested modal when hitting apply
    setShowTimeRangeModal(false);
    handleApplyInterval(data);
  };

  const enableSubmit = () => Object.values(selectedReport).some((value) => value) && reportName;

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title className="fs-3 my-0">
          Create a New Report
        </Modal.Title>
        <Button variant="white" onClick={() => setShowTimeRangeModal(true)}>
          <span className="h4 pe-2">
            Time Range
          </span>
          <FeatherIcon
            icon="calendar"
            size="1.3em"
            className="mb-1 text-black"
          />
          <span className="h4 ms-2">
            {currentTimeRange.timePeriod} {currentTimeRange.year}
          </span>
        </Button>
      </Modal.Header>
      <ListGroup className="ps-0">
        <ListGroup.Item>
          <Form.Group>
            <Form.Label>Please enter a name for this report</Form.Label>
            <Form.Control value={reportName} type="text" onChange={(change) => setReportName(change.target.value)}/>
          </Form.Group>
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Executive Summary"
            reportBody="Summary given from Fondo on the data you are viewing."
            sectionIsSelected={selectedReport.executiveSummary}
            reportingImage={reportingExecutiveSummary}
            handleChange={(change) => setSelectedReport({ ...selectedReport, executiveSummary: change.target.checked })}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Runway"
            reportBody="Viewing your companies runway broken down in charts easy to understand."
            sectionIsSelected={selectedReport.runway}
            reportingImage={reportingRunway}
            handleChange={(change) => setSelectedReport({ ...selectedReport, runway: change.target.checked })}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Profit and Loss"
            reportBody="Breakdown of revenue, cost, and expenses incurred."
            sectionIsSelected={selectedReport.profitAndLoss}
            reportingImage={reportingProfitAndLoss}
            handleChange={(change) => setSelectedReport({ ...selectedReport, profitAndLoss: change.target.checked })}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Balance Sheet"
            reportBody="Breakdown of assets, liabilities, and shareholder equity."
            sectionIsSelected={selectedReport.balanceSheet}
            reportingImage={reportingBalanceSheet}
            handleChange={(change) => setSelectedReport({ ...selectedReport, balanceSheet: change.target.checked })}
          />
        </ListGroup.Item>
      </ListGroup>
      {missingBankConnection && (
        <Alert variant="warning" className="info mt-3">
          <span>
            The {missingBankSections(selectedReport)}{" "}
            section{selectedReport.executiveSummary && selectedReport.runway ? "s" : ""} will 
            have missing data if the report is 
            created because there are no connected bank accounts.
          </span>
        </Alert>
      )}
      {missingQBOConnection() && (
        <Alert variant="warning" className="info mt-3">
          <span>
            The {missingQBOSections(selectedReport)}{" "}
            section{selectedReport.executiveSummary && selectedReport.runway ? "s" : ""} will
            have missing data if the report is 
            created because there are no connected QBO accounts.
          </span>
        </Alert>
      )}
      <Modal.Footer>
        <Button variant="white" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={!enableSubmit()} variant="primary" onClick={createNewReport}>
          {createNewReportMutation.isLoading ?
            <Spinner as="span" animation="border" size="sm" /> :
            <span>Generate Report</span>
          }
        </Button>
      </Modal.Footer>
      {/* Created a modal for the time range selector to not stretch the header */}
      <Modal
        show={showTimeRangeModal}
        onHide={() => setShowTimeRangeModal(false)}
        size="sm"
      >
        <TimeRangeIntervalSelector
          balanceAvailableIntervals={balanceAvailableIntervals}
          handleApplyInterval={closeTimeRangeModel}
          currentTimeRange={currentTimeRange}
          className="my-0"
          openByDefault={true}
        />
      </Modal>
    </Modal>
  );
};

export default CreateNewReportModal;
