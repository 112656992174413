import { Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { TEN_PERCENT_OPACITY } from "../passport-partners.types";

type PartnerFilterButtonProps = {
  icon?: string;
  color?: string;
  text: string;
  filterEnabled: boolean;
  setFilterEnabled: (filterEnabled: boolean) => void;
};

export const PartnerFilterButton = ({
  icon,
  color,
  text,
  filterEnabled,
  setFilterEnabled,
}: PartnerFilterButtonProps) => (
  <Button
    variant={filterEnabled ? "dark" : "white"}
    style={color && !filterEnabled ? { color, background: color + TEN_PERCENT_OPACITY } : undefined}
    className="me-2 mb-2 py-2 px-3"
    onClick={() => setFilterEnabled(!filterEnabled)}
    size="sm"
  >
    {icon && <FeatherIcon icon={icon} size="18" className="me-2 mb-1" />}
    {text}
  </Button>
);
