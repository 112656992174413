import { useFormikContext } from "formik";
import {
  CorpTaxFormFields,
} from "./corporateTax.type";
import FondoFormGroup from "../forms/formik-components/FondoFormGroup";
import {
  post2023CompanyInfoFieldToLabelMap,
  post2023CompanyInfoFieldToTypeMap,
} from "./post-2023/post2023CorporateTax.type";

export default function AdditionalInfo() {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  return (
    <div>

      <form onSubmit={formikContext.handleSubmit}>
        <FondoFormGroup
          fieldName="companyInfo.additionalInformation"
          labels={post2023CompanyInfoFieldToLabelMap()}
          types={post2023CompanyInfoFieldToTypeMap}
          formikContext={formikContext}
        />
      </form >
    </div >
  );
}
