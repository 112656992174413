export const fondoEnv = process.env.REACT_APP_FONDO_ENVIRONMENT;
export const CSRF_COOKIE_NAME = `csrftoken${fondoEnv}`;
export const SESSION_COOKIE_NAME = `sessionid${fondoEnv}`;
export const PDF_COOKIE_NAME = "pdf_token";

export const PATHS_TO_HIDE_SIDEBAR = [
  "/login",
  "/accounts/login",
  "/onboarding",
  "/onboarding/signup",
  "/onboarding/company1",
  "/onboarding/company2",
  "/onboarding/activity1",
  "/onboarding/activity2",
  "/onboarding/tax-plan",
  "/accounts/password/reset/",
  "/accounts/password/reset/confirm/:uid/:token",
  "/onboarding/product",
  "/onboarding/onboarding-success",
  "/onboarding/non-delaware-c-corp",
  "/dashboard-onboarding",
  "/dashboard-onboarding/user",
  "/dashboard-onboarding/company",
  "/dashboard-onboarding/connections",
  "/payroll_connection",
  "/payroll_manual_connection",
  "/taxplan-data",
  "/onboarding/checkout",
  "/passport-partners/reviews",
];

export const PUBLIC_ROUTES = [
  "/login",
  "/accounts/login",
  "/onboarding",
  "/onboarding/signup",
  "/accounts/password/reset/",
  "/accounts/password/reset/confirm/:uid/:token",
  "/dashboard-onboarding",
  "/dashboard-onboarding/user",
];

// SIGNUP TIMELINE
export const getSignupTimelineItems = (newCheckoutPage = false) => {
  const SIGNUP_TIMELINE_ITEMS = [
    { title: "Personal", key: "signup", number: "1" },
    { title: "Company", key: "company", number: "2" },
    { title: "Activity", key: "activity", number: "3" },
    { title: "TaxPlan", key: "tax-plan", number: "4" },
  ];

  let instantQuoteKey = "product";
  if (newCheckoutPage) {
    instantQuoteKey = "checkout";
  }

  SIGNUP_TIMELINE_ITEMS.push({
    title: "Instant Quote",
    key: instantQuoteKey,
    number: "ϟ",
  });
  return SIGNUP_TIMELINE_ITEMS;
};

export const getOnboardingSteps = (newCheckoutPage = false) => {
  const ONBOARDING_STEPS = ["signup", "company1", "company2", "activity1", "activity2", "tax-plan"];

  if (newCheckoutPage) {
    ONBOARDING_STEPS.push("checkout");
  } else {
    ONBOARDING_STEPS.push("product");
  }
  ONBOARDING_STEPS.push("onboarding-success");
  return ONBOARDING_STEPS;
};

export const STEPS_TO_HIDE_TIMELINE = ["onboarding-success", "non-delaware-c-corp"];

// DASHBOARD SIGNUP TIMELINE
export const DASHBOARD_ONBOARDING_TIMELINE_ITEMS = [
  { title: "Welcome", key: "user", number: "1" },
  { title: "Company", key: "company", number: "2" },
  { title: "Connect", key: "connections", number: "3" },
];

export const DASHBOARD_ONBOARDING_STEPS = ["user", "company", "connections"];

// iframe messages
export const NAVIGATION = "navigation";
export const PAGE_LOAD = "pageLoad";
export const SIDENAV_COLLAPSE = "sidenavCollapse";
export const IFRAME_MESSAGE_TYPES = [NAVIGATION, PAGE_LOAD, SIDENAV_COLLAPSE];

// Form error messages
export const FORM_ERROR_MSG = {
  REQUIRED_FIELD: "This field is required.",
  REQUIRED_FIELD_CUSTOM: "Please enter your ",
  REQUIRED_PHONE: "Please enter your phone number.",
  NUMBER_GT: (minNumber: number) => `Ensure this value is greater than or equal to ${minNumber}.`,
  NUMBER_LT: (maxNumber: number) => `Ensure this value is less than or equal to ${maxNumber}.`,
  INVALID_URL: "Enter a valid URL.",
  MAX_LENGTH: (length: number) => `number of characters cannot be more than ${length}`,
};

// RegEx
export const REG_EX = {
  // Long regexs, not a huge benefit of adding regex constructor since it'll still be unreadable
  // eslint-disable-next-line max-len
  URL: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
  // eslint-disable-next-line max-len
  FOREIGN_SUBSIDIARY:
    /\/foreign_subsidiary\/[0-9]{4}\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/i,
  CORP_TAX_ONBOARDING: /\/corp_tax_onboarding\/[0-9]{4}/i,
  CORP_TAX_ONBOARDING_NEW: /\/corp_tax_onboarding_new\/[0-9]{4}/i,
};

export function regexTest(input: string, regex: any) {
  return regex.test(input);
}

export const PATHS_TO_COLLAPSE_SIDEBAR = [
  REG_EX.CORP_TAX_ONBOARDING,
  REG_EX.FOREIGN_SUBSIDIARY,
  REG_EX.CORP_TAX_ONBOARDING_NEW,
];

export function isSidenavCollapsedPath(path: string) {
  return PATHS_TO_COLLAPSE_SIDEBAR.some((p) => regexTest(path, p));
}

// Company onboarding
export const LOGIN_BACKEND_POSSIBLE_ERRORS = [
  "The e-mail address and/or password you specified are not correct.",
  "Access denied: wrong username or password.",
];
export const BATCH_CHOICES = [
  "n/a",
  "S24",
  "W24",
  "S23",
  "W23",
  "S22",
  "W22",
  "W21",
  "S21",
  "S20",
  "W20",
  "S19",
  "W19",
  "S18",
  "W18",
  "S17",
  "W17",
  "S16",
  "W16",
  "S15",
  "W15",
  "S14",
  "W14",
  "S13",
  "W13",
  "S12",
  "W12",
  "S11",
  "W11",
  "S10",
  "W10",
  "S09",
  "W09",
  "S08",
  "W08",
  "S07",
  "W07",
  "S06",
  "W06",
  "S05",
  "W05",
];
export const ACCELERATOR_CHOICES = ["Y Combinator", "TechStars", "On Deck", "Angel funded", "Bootstrapped", "Other"];
export const HOW_HEARD_CHOICES = [
  "YC",
  "Puzzle",
  "SaaStr",
  "Bookface",
  "Firstbase",
  "Finger Lakes Summit",
  "OnDeck",
  "Gusto",
  "Brex",
  "Warp",
  "Atlas",
  "Rho",
  "Other",
];
export const PRODUCT_CATEGORIES = ["Tax", "Tax Add-ons", "Bookkeeping"];
// icon types
export const BETA = "beta";
export const NEW = "new";
export const BENTO_PLUS_1 = "bento-plus-one";
export const ACTION_ITEMS_PLUS_1 = "action-items-plus-one";
export const MARK_DONE = "check-circle-success";
export const HELP_WARNING = "help-circle-warning";

// 1099 Filings
export const REQUIRED_1099_PAYMENT_METHODS = ["Wire Transfer", "ACH", "Bill Pay System", "Other"];
export const PAYMENT_METHOD_CHOICES = [
  { key: "Wire Transfer", value: "Wire Transfer" },
  { key: "ACH", value: "ACH" },
  { key: "Credit Card", value: "Credit Card" },
  { key: "PayPal", value: "PayPal" },
  { key: "Bill Pay System", value: "Bill Pay System (Bill.com, Ramp, etc.)" },
  { key: "Payroll System", value: "Payroll System (Gusto, Rippling, etc.)" },
  { key: "Other", value: "Other" },
];
export const FEDERAL_ID_TYPE_CHOICES = [
  { key: "EIN", value: "EIN" },
  { key: "SSN", value: "SSN" },
  { key: "ITIN", value: "ITIN" },
  { key: "ATIN", value: "ATIN" },
];
export const PAYMENT_DESCRIPTION_CHOICES = [
  { key: "Consultant", value: "Consultant" },
  { key: "Contractor", value: "Contractor" },
  { key: "Agency", value: "Agency" },
  { key: "Legal/Attorney Fees - Services", value: "Legal/Attorney Fees - Services" },
  {
    key: "Legal/Attorney Fees - Gross Proceeds",
    value: "Legal/Attorney Fees - Gross Proceeds (example: Lawsuit Settlement)",
  },
  { key: "Office Rent", value: "Office Rent" },
  { key: "Other", value: "Other" },
];
export const FEDERAL_TAX_CLASSIFICATION_CHOICES = [
  { key: "Individual/Sole Propietor/Single Member LLC", value: "Individual/Sole Propietor/Single Member LLC" },
  { key: "C Corporation", value: "C Corporation" },
  { key: "S Corporation", value: "S Corporation" },
  { key: "Trust/Estate", value: "Trust/Estate" },
  { key: "LLC (taxed as C Corp)", value: "LLC (taxed as C Corp)" },
  { key: "LLC (taxed as S Corp)", value: "LLC (taxed as S Corp)" },
  { key: "LLC (taxed as Partnership)", value: "LLC (taxed as Partnership)" },
  { key: "LLP", value: "LLP" },
  { key: "PC", value: "PC" },
  { key: "Other", value: "Other" },
];

export const PERCENTAGE_MULTIPLE_OF_5 = [
  "0",
  "5",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "90",
  "95",
  "100",
];

export const CONTACT_ENGINEERING_ERROR_MESSAGE = "An error occurred, please contact the Engineering Team";
