import { useParams } from "react-router";
import PayrollManualConnectionForm from "./PayrollManualConnectionForm";
import FondoBaseLayout from "../../fusion-kit/FondoBaseLayout";

const PayrollManualConnection = () => {

  const { taxYear } = useParams<{ taxYear: string }>();


  if (taxYear === undefined) {
    throw new Error("taxYear is undefined");
  }

  return (
    <FondoBaseLayout >
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8 mx-3">
          <h2>TaxPlan - {taxYear}</h2>
          <p className="text-muted">
            Please tell us about your company in order to activate your {taxYear} Fondo TaxPlan.
          </p>
          <PayrollManualConnectionForm taxYear={taxYear}/>
        </div>
      </div>
    </FondoBaseLayout>
  );
}


export default PayrollManualConnection;
