import { useEffect } from "react";
import FondoLayout from "../fusion-kit/FondoLayout";
import actionItemsPayDeposit from "../../assets/img/illustrations/receipt.svg";

const ActionItemsPendingDepositView = () => {


  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);


  return(
    <FondoLayout
      title="Action Items"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div
              className="px-6 mx-auto text-center col-12 col-sm-8 col-lg-10 col-xl-8"
              style={{ marginTop: "150px" }}
            >
              <div>
                <img height={60} src={actionItemsPayDeposit} className="mx-auto" alt="..." />
                <h1 className="my-3">
                  Please wait
                </h1>
                <h2 className="mb-3 fw-normal text-secondary">
                  Your payment is being processed
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FondoLayout>
  );
};

export default ActionItemsPendingDepositView;
