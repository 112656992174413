import { useFeatureFlagEnabled } from "posthog-js/react";
import { Row } from "react-bootstrap";
import FondoBGEllipseLayout from "../fusion-kit/FondoBGEllipseLayout";
import BankAccountsIntegrationCard from "./BankAccountsIntegrationCard";
import MergeDevCard from "./MergeDevCard";
import ThirdPartyIntegrationCard from "./ThirdPartyIntegrationCard";

export default function Integrations() {
  const isMergeDevHRISEnabled = useFeatureFlagEnabled("merge-dev-hris");

  let subtitle = "Connect Fondo to your bank accounts.";
  if (isMergeDevHRISEnabled) {
    subtitle = "Connect Fondo to your bank accounts and other integrations.";
  }
  return (
    <div className="main-content">
      <FondoBGEllipseLayout title="Integrations" subtitle={subtitle} />
      <div className="mx-4">
        <Row className="row mt-n7">
          <div className="col-sm-6">
            <BankAccountsIntegrationCard />
          </div>
          <div className="col-sm-6">
            {isMergeDevHRISEnabled && <MergeDevCard category="hris" title="HR" />}
            <ThirdPartyIntegrationCard source="QBO" />
          </div>
        </Row>
      </div>
    </div>
  );
}
