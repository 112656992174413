import { Badge } from "react-bootstrap";

export const Top10Badge = () => (
  <Badge
    bg="warning"
    style={{ color: "#655815", position: "absolute", left: 0, right: 0, top: "-0.5rem", width: "fit-content" }}
    className="mx-auto pb-1"
  >
    TOP 10
  </Badge>
);
