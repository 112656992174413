import { ReactNode } from "react";
import FeatherIcon from "feather-icons-react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { convertNumberToCurrency } from "../../../utils/utils";
import { InternationalActivity, TaxPlanResult } from "../financeDashboard.type";

export type OptionalInternationalActivity = {
  foreignShareholders?: number;
  foreignBankAccounts?: number;
  internationalSubsidiaries?: number;
};

const internationalActivityTypes = [
  {
    type: "foreignShareholders",
    fullVerbiage: "Foreign Shareholder",
    singularVerbiage: "shareholder",
    formNumber: "5472",
    penaltyAmount: 25000,
  },
  {
    type: "internationalSubsidiaries",
    fullVerbiage: "Foreign Subsidiary",
    singularVerbiage: "subsidiary",
    formNumber: "5471",
    penaltyAmount: 10000,
  },
  {
    type: "foreignBankAccounts",
    fullVerbiage: "Foreign Bank and Financial Accounts",
    singularVerbiage: "account",
    formNumber: "114",
    penaltyAmount: 10000,
  },
];

export const getInternationalActivityTableRows = (
  taxPlanResultData: TaxPlanResult,
) => {
  const removedZerosData: OptionalInternationalActivity = {
    foreignShareholders: taxPlanResultData?.numberOfForeignShareholders,
    internationalSubsidiaries: taxPlanResultData?.numberOfInternationalSubsidiaries,
    foreignBankAccounts: taxPlanResultData?.numberOfForeignBankAccounts,
  };

  if (!removedZerosData.foreignBankAccounts) {
    delete removedZerosData.foreignBankAccounts;
  }
  if (!removedZerosData.foreignShareholders) {
    delete removedZerosData.foreignShareholders;
  }
  if (!removedZerosData.internationalSubsidiaries) {
    delete removedZerosData.internationalSubsidiaries;
  }

  return <>
    {Object.keys(removedZerosData).map((key) => (
      <tr>
        <td>
          <p className="fs-4 mb-0">
            Form {internationalActivityTypes.find((activityType: any) => (
            key === activityType.type
          ))?.formNumber}
          </p>
          <span className="text-muted">
            {internationalActivityTypes.find((activityType: any) => (
              key === activityType.type
            ))?.fullVerbiage}
          </span>
        </td>
        <td className="text-center">
        {removedZerosData[key as keyof InternationalActivity]}
        </td>
        <td>
          <p className="fs-4 mb-0">April 15, 2024</p>
          <span className="text-muted">with Federal Return</span>
        </td>
        <td>
          <span className="text-danger fs-4">
            ${convertNumberToCurrency(internationalActivityTypes.find((activityType: any) => (
            key === activityType.type
          ))?.penaltyAmount || 0)}
            {" "} per {internationalActivityTypes.find((activityType: any) => (
              key === activityType.type
            ))?.singularVerbiage}
          </span>
        </td>
        <td className="text-center">-</td>
      </tr>
    ))}
  </>;
};

export const getTooltipPopover = (helpTooltipHTML: ReactNode) => {
  const tooltipPopover = (
    <Popover style={{ maxWidth: "30rem" }}>
      <Popover.Body>
        {helpTooltipHTML}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={tooltipPopover}
      rootClose // to close when clicking elsewhere
    >
      <a
        role="button"
        tabIndex={0}
      >
        <FeatherIcon
          icon="info"
          size="15"
          className="ms-2 mt-n1 text-secondary"
        />
      </a>
    </OverlayTrigger>
  );
};

export const rDCreditEstimateTooltipContent = (
  <>
    <p className="my-2">• This is your estimated Federal Research & Development Credit</p>
    <p className="my-2">
      • It can be up to $500,000 per year - & you {" "}
      <strong>do not</strong> need to be profitable to claim</p>
    <p className="my-2">
      • The credit can be claimed against income tax or payroll tax. Most startups claim it
      against payroll tax, which makes this credit refundable - your startup gets a check
      directly from the IRS
    </p>
    <p className="my-2">
      • Learn more about Tax Credits {" "}
      <a
        href="https://www.tryfondo.com/guides/a-startups-guide-to-tax-credits"
        target="_blank"
      >
        here
      </a>
    </p>
  </>
);

export const taxableIncomeEstimateTooltipContent = (
  <>
    <p className="my-2">• This is your estimated Taxable Income - your taxes owed are based on this</p>
    <p className="my-2">
      • It is calculated by taking your Net Income and adding back non-deductible Section 174 expenses
    </p>
    <p className="my-2">
      • Learn more about Section 174 {" "}
      <a
        href="https://www.tryfondo.com/blog/section-174"
        target="_blank"
      >
        here
      </a>
    </p>
  </>
);

export const taxesOwedEstimateTooltipContent = (
  <>
    <p className="my-2">• This is your estimated Taxes Owed</p>
    <p className="my-2">
      • It is calculated by adding up all of the taxes you owe across all of the
      jurisdictions where you have corporate tax nexus
    </p>
    <p className="my-2">
      • Learn more about Corporate Taxes {" "}
      <a
        href="https://www.tryfondo.com/guides/founders-guide-to-corporate-taxes"
        target="_blank"
      >
        here
      </a>
    </p>
  </>
);

export const section174TooltipContent = (
  <div className="text-primary">
    <p className="my-2">• Section 174 is a new tax law that was put in effect in 2022</p>
    <p className="my-2">
      • This has an impact on most startups - it can create a taxable "profit"
      even when your company is operating at a loss
    </p>
    <p className="my-2">
      • Learn more about Section 174 and how it impacts your startup{" "}
      <a
        href="https://www.tryfondo.com/blog/section-174"
        target="_blank"
        className="text-decoration-underline"
      >
        here
      </a>
    </p>
  </div>
);
