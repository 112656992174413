import {
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
  Spinner,
  ButtonProps,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import {
  RequiredData,
  SelectedDealStatus,
  SelectedDealStatusToColorMap,
  SelectedDealStatusToDisplayMap,
  ReviewStatus,
  Partner,
  Product,
  UnavailableRequiredData,
} from "../passport-partners.types";
import { FiveStars } from "../../fondo-components/FiveStars";
import { PartnerCardTopBar } from "../PartnerCardTopBar";
import { getUnavailableRequiredData } from "../passport-partners.api";
import { copyToClipboard } from "../../../utils/utils";
import { MissingInfoModal } from "./MissingInfoModal";
import { UpsertReviewModal } from "../reviews/ReviewModal";
import { ReviewContent } from "../reviews/ReviewContent";

import { ConnectingModal } from "./ConnectingModal";
import { FondoPartnerLogos } from "../FondoPartnerLogos";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import { getApiClient } from "../../../utils/util.api";

type DealDetailsModalProps = { partner: Partner; product?: Product; show: boolean; onHide: () => void };

export const DealDetailsModal = ({ partner, product, show, onHide }: DealDetailsModalProps) => {
  const queryClient = useQueryClient();
  const { currentCompanyId } = useContext(LoggedInUserContext);
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const [showCreateReviewModal, setShowCreateReviewModal] = useState(false);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [showConnectingModal, setShowConnectingModal] = useState(false);
  const deal = product?.deals[0];
  const { selectedDeal } = partner;
  const selectDealMutation = useMutation(
    async ({ dealUuid, companyId }: { dealUuid: string; companyId: number }) => {
      const client = await getApiClient();
      const response = await client.createReactifyPassportPartnersSelectedDeals(null, {
        deal: dealUuid,
        company: companyId,
      });
      return response.data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["passportPartnersPartners"]);
      },
    },
  );

  const unavailableRequiredDataQuery = useQuery(
    ["passportPartnersUnavailableRequiredData", deal?.uuid],
    () => (deal ? getUnavailableRequiredData(deal.uuid) : []),
    {
      enabled: show && deal && !partner.selectedDeal && !partner.canReviewPartner,
      refetchOnWindowFocus: false,
    },
  );

  const handleHide = () => {
    onHide();
    setShowAllReviews(false); // reset to main details view
  };

  const onConnect = () => {
    if (deal && currentCompanyId !== null) {
      selectDealMutation.mutate({
        dealUuid: deal.uuid,
        companyId: currentCompanyId,
      });
    }
    handleHide();
    setShowConnectingModal(true);
  };

  const logoUrl = partner.logoUrl || "";

  const requiredData = deal?.requiredData || [];
  const unavailableRequiredDataIsSuccess = unavailableRequiredDataQuery.isSuccess;
  const unavailableRequiredData = unavailableRequiredDataQuery.data || [];
  const unavailableRequiredDataUuidToInfoMap = new Map(
    unavailableRequiredData.map((info) => [info.uuid, info] as [string, UnavailableRequiredData]),
  );
  const allRequiredDataAvailable = unavailableRequiredData.length === 0;

  const renderRequiredDataItem = (requiredDataItem: RequiredData, idx: number) => {
    let textColorClass = "";
    const isAvailable = allRequiredDataAvailable || !unavailableRequiredDataUuidToInfoMap.has(requiredDataItem.uuid);
    const { unavailabilityDescription } = unavailableRequiredDataUuidToInfoMap.get(requiredDataItem.uuid) || {};
    if (unavailableRequiredDataIsSuccess) {
      textColorClass = isAvailable ? "text-primary" : "text-danger";
    }
    const tooltip = (
      <Tooltip>
        {requiredDataItem.description}
        {!isAvailable && unavailabilityDescription && ` • ${unavailabilityDescription}`}
      </Tooltip>
    );
    return (
      <OverlayTrigger overlay={tooltip} key={idx}>
        <p className={`mb-1 cursor-help ${textColorClass}`} style={{ width: "fit-content" }}>
          {unavailableRequiredDataIsSuccess && !isAvailable && (
            <FeatherIcon className="mb-1 me-1" icon="alert-circle" size="16" />
          )}
          {requiredDataItem.displayName}
        </p>
      </OverlayTrigger>
    );
  };

  const getModalFooterContent = () => {
    let actionButton = <div />;
    const buttonStyle = {
      style: { width: "fit-content" },
      className: "text-nowrap mt-auto",
      size: "sm",
    } as ButtonProps;
    if (partner.canReviewPartner) {
      const reviewButtonText = `${
        partner.existingReview && partner.existingReview.reviewStatus !== ReviewStatus.DISMISSED ? "Edit" : "Leave a"
      } Review`;
      actionButton = (
        <Button variant="primary" {...buttonStyle} onClick={() => setShowCreateReviewModal(true)}>
          {reviewButtonText}
        </Button>
      );
    } else if (deal) {
      actionButton = (
        <Button
          variant={selectedDeal ? SelectedDealStatusToColorMap[selectedDeal.status] : "primary"}
          {...buttonStyle}
          onClick={onConnect}
          disabled={Boolean(partner.selectedDeal) || !unavailableRequiredDataIsSuccess || !allRequiredDataAvailable}
        >
          {selectedDeal ? SelectedDealStatusToDisplayMap[selectedDeal.status] : "Click to Connect"}
        </Button>
      );
    }
    return (
      <>
        {selectedDeal?.displayStatus ? (
          <span className="text-secondary user-select-none me-2 text-left">{selectedDeal.displayStatus}</span>
        ) : (
          <div />
        )}

        {actionButton}
      </>
    );
  };

  const getDealStatusMessage = () => {
    let cardContent = null;
    const getMessageSpan = (text: string) => (
      <span className="text-white text-center user-select-none pt-1 pe-2">{text}</span>
    );
    if (selectedDeal) {
      if (
        selectedDeal.status === SelectedDealStatus.ACTION_NEEDED ||
        selectedDeal.status === SelectedDealStatus.ACCEPTED
      ) {
        const actionNeededUrl = selectedDeal.actionNeededUrl || "";
        let completeActionButton = <div />;
        if (actionNeededUrl.length) {
          completeActionButton = (
            <ButtonGroup>
              <Button as="a" target="_blank" href={actionNeededUrl} size="sm">
                Complete Action
              </Button>
              <OverlayTrigger overlay={<Tooltip>Copy link</Tooltip>}>
                <Button onClick={() => copyToClipboard(actionNeededUrl)} size="sm">
                  <FeatherIcon icon="copy" size="1.2rem"></FeatherIcon>
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          );
        }
        cardContent = (
          <>
            {getMessageSpan(selectedDeal.actionNeededText || "Action needed to complete the connection")}
            {completeActionButton}
          </>
        );
      } else if (selectedDeal.status === SelectedDealStatus.REJECTED) {
        cardContent = (
          <>
            {getMessageSpan(selectedDeal.rejectionReason || "Connection request was rejected")}
            <div />
          </>
        );
      }
    } else if (unavailableRequiredDataIsSuccess && !allRequiredDataAvailable) {
      cardContent = (
        <>
          {getMessageSpan("We need a little more info to connect")}
          <Button size="sm" onClick={() => setShowMissingInfoModal(true)}>
            Add Now
          </Button>
        </>
      );
    } else if (!unavailableRequiredDataQuery.isLoading && !unavailableRequiredDataIsSuccess) {
      cardContent = (
        <>
          {getMessageSpan("We are having trouble loading the required data info. Please try again later.")}
          <div />
        </>
      );
    }
    return (
      cardContent && (
        <Card className="bg-dark w-100">
          <Card.Body className="d-flex justify-content-between p-3">{cardContent}</Card.Body>
        </Card>
      )
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleHide}>
        {showAllReviews ? (
          <ReviewContent
            partnerId={partner.uuid}
            showAllReviews={showAllReviews}
            setShowAllReviews={setShowAllReviews}
          />
        ) : (
          <>
            <Modal.Header className="border-bottom-0" closeButton />
            <Modal.Body className="d-flex flex-column align-items-center py-0">
              <a className="d-block" style={{ maxWidth: "30%" }} href={partner.url} target="_blank">
                <img src={logoUrl} className="w-100" />
              </a>
              <h5 className="mt-3" style={{ color: "#6E84A3" }}>
                {product?.description || partner.description}
              </h5>
              <div className="d-flex justify-content-between w-100">
                <div>
                  Using{" "}
                  <span className="number-using-deal">{product?.numberUsingProduct || partner.numberUsingPartner}</span>
                </div>
                <div className="d-flex flex-nowrap">
                  Rating <FiveStars stars={product?.averageScore || partner.averageScore} className="ms-2" />
                </div>
              </div>
              <hr className="border-top w-100" />
              <Card className="w-100">
                <PartnerCardTopBar />
                <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: deal?.description || "No Active Deal" }} />
                </Card.Body>
              </Card>
              <ReviewContent
                partnerId={partner.uuid}
                showAllReviews={showAllReviews}
                setShowAllReviews={setShowAllReviews}
              />
              {deal && !selectedDeal && !partner.canReviewPartner && (
                <Card className="w-100">
                  <PartnerCardTopBar />
                  <Card.Body className="pb-3">
                    <Card.Title as="h3">
                      Data Sync Account Creation{" "}
                      {unavailableRequiredDataQuery.isLoading && <Spinner size="sm" variant="primary" />}
                    </Card.Title>
                    <div className="d-flex flex-nowrap justify-content-between">
                      <div style={{ width: "40%" }} className="text-secondary">
                        {requiredData.map(renderRequiredDataItem)}
                      </div>
                      <FondoPartnerLogos partnerLogoUrl={logoUrl} />
                    </div>
                  </Card.Body>
                  <Card.Footer className="text-secondary p-3 fs-5 user-select-none">
                    Hover over the required data to see more information.
                  </Card.Footer>
                </Card>
              )}
              {getDealStatusMessage()}
            </Modal.Body>
            <Modal.Footer className="border-top-0 d-flex flex-nowrap justify-content-between pt-0">
              {getModalFooterContent()}
            </Modal.Footer>
            {deal && (
              <MissingInfoModal
                show={showMissingInfoModal}
                onHide={() => setShowMissingInfoModal(false)}
                deal={deal}
                missingRequiredData={unavailableRequiredData}
              />
            )}
            <UpsertReviewModal
              show={showCreateReviewModal}
              onHide={() => setShowCreateReviewModal(false)}
              product={product}
              partner={partner}
            />
          </>
        )}
      </Modal>
      <ConnectingModal
        show={showConnectingModal}
        onHide={() => {
          setShowConnectingModal(false);
        }}
        partnerLogoUrl={logoUrl}
        isComplete={Boolean(selectedDeal)}
      />
    </>
  );
};
