import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FORM_ERROR_MSG } from "../../../constants/general";
import { FieldTypes } from "../../forms/formik-components/formTypes";
import {
  AddressFormFields,
  ForeignBankAccountFormFields,
  MajorityShareholderFormFields,
  ResearchExpenseFormFields,
  USSubsidiaryFormFields,
  addressSchema,
  foreignBankAccountSchema,
  majorityShareholderSchema,
  officerSchema,
  researchExpenseSchema,
  usSubsidiarySchema,
} from "../corporateTax.type";

export type Post2023CorporateTaxStep = {
  name: string;
  displayLabel: string;
  icon: string;
  enabled: boolean | ((formik: FormikProps<Post2023CorpTaxFormFields>) => boolean);
  taxYear?: string;
};

export type Post2023CorpTaxFormFields = {
  submitted?: boolean;
  incorporation: Post2023IncorporationFormFields;
  address: AddressFormFields;
  companyInfo: Post2023CompanyInfoFormFields;
  researchExpenses: ResearchExpenseFormFields;
  majorityShareholders: MajorityShareholderFormFields[];
  usSubs: USSubsidiaryFormFields[];
  foreignBanks: ForeignBankAccountFormFields[];
  oldOfficers: string[];
  oldMajorityShareholders: string[];
  oldForeignBanks: string[];
  oldUsSubs: string[];
}

export type Post2023IncorporationFormFields = {
  uuid?: string;
  legalName?: string;
  name?: string;
  einEnc?: string;
  incorporationDate?: Date;
  fiscalYearEnd?: string;
  incorporationState?: string;
};

export const post2023IncorporationSchema = Yup.object({
  legalName: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  name: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  einEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  incorporationDate: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  fiscalYearEnd: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(4, FORM_ERROR_MSG.REQUIRED_FIELD),
  incorporationState: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(5, FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const post2023IncorporationFieldToLabelMap = {
  legalName: "Legal Name:",
  name: "DBA Name (if applicable):",
  einEnc: "EIN:",
  incorporationDate: "Date of Incorporation:",
  fiscalYearEnd: "Fiscal Year-End:",
  incorporationState: "State of Incorporation:",
};

export const post2023IncorporationFieldToPlaceholderMap = {
  legalName: "Bloomjoy, Inc.",
  name: "Fondo",
};

export const post2023IncorporationFieldToTypeMap = {
  legalName: FieldTypes.TEXT,
  name: FieldTypes.TEXT,
  einEnc: FieldTypes.EIN,
  incorporationDate: FieldTypes.DATE,
  fiscalYearEnd: FieldTypes.DATE_OF_YEAR,
  incorporationState: FieldTypes.STATE,
};

export type Post2023CompanyInfoFormFields = {
  uuid?: string;
  conversion?: string;
  hasFiled1099?: string;
  hasSubsidiaries?: string;
  hasForeignSubsidiaries?: string;
  numberOfForeignSubsidiaries?: number;
  hasForeignAccounts?: string;
  numberOfForeignAccounts?: number;
  additionalInformation?: string;
};

export const post2023CompanyInfoSchema = Yup.object({
  conversion: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasFiled1099: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasSubsidiaries: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignSubsidiaries: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  numberOfForeignSubsidiaries: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(0, "Cannot have less than 0."),
  hasForeignAccounts: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  numberOfForeignAccounts: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(0, "Cannot have less than 0."),
  additionalInformation: Yup.string().optional().nullable(),
});

export const post2023CompanyInfoFieldToTypeMap = {
  conversion: FieldTypes.YESNO,
  hasFiled1099: FieldTypes.YESNO,
  hasSubsidiaries: FieldTypes.YESNO,
  hasForeignSubsidiaries: FieldTypes.YESNO,
  numberOfForeignSubsidiaries: FieldTypes.INTEGER,
  hasForeignAccounts: FieldTypes.YESNO,
  numberOfForeignAccounts: FieldTypes.INTEGER,
  additionalInformation: FieldTypes.TEXTAREA,
};

export const post2023CompanyInfoFieldToLabelMap = () => {
  const { taxYear } = useParams();

  if (!taxYear) {
    return {
      conversion: "",
      hasFiled1099: "",
      hasSubsidiaries: "",
      hasForeignSubsidiaries: "",
      numberOfForeignSubsidiaries: "",
      hasForeignAccounts: "",
      numberOfForeignAccounts: "",
      additionalInformation: "",
    };
  }

  return {
    conversion: `Did your company convert from an LLC, S-Corp, or any type of entity in ${taxYear}?`,
    hasFiled1099: `Did you file 1099s for ${taxYear}?`,
    hasSubsidiaries: `Do you have any U.S. subsidiaries for ${taxYear}?`,
    hasForeignSubsidiaries: `Do you have any foreign subsidiaries for ${taxYear}?`,
    numberOfForeignSubsidiaries: "How many?",
    hasForeignAccounts: `Do you have any foreign bank accounts ${taxYear}?`,
    numberOfForeignAccounts: "How many?",
    // eslint-disable-next-line max-len
    additionalInformation: "Please let us know if there is any other information that will be helpful or necessary to prepare your documents",
  };
}

export const getPost2023CorporateTaxSchema = (taxYear: number) => {
  const initialCorporateTaxSchema: any = {
    incorporation: post2023IncorporationSchema,
    address: addressSchema,
    officers: Yup.array(officerSchema).min(1, "Need at least one officer")
      .test("has-signing-officer", "Need one signing officer", (officers) => {
        if (officers) {
          return officers.some((officer) => officer.signingOfficer === "true");
        }
        return false;
      }),
    companyInfo: post2023CompanyInfoSchema,
    majorityShareholders: Yup.array(majorityShareholderSchema),
    usSubs: Yup.array(usSubsidiarySchema).when(
      "companyInfo.hasSubsidiaries",
      (hasSubs, field) => (
        hasSubs === "True" ? field.min(1) : field
      ),
    ),
    foreignBanks: Yup.array(foreignBankAccountSchema).when(
      "companyInfo.hasForeignAccounts",
      (hasForeignAccounts, field) => (
        hasForeignAccounts === "True" ? field.min(1) : field
      ),
    ),
  };

  if (taxYear >= 2022) {
    initialCorporateTaxSchema.researchExpenses = researchExpenseSchema;
  }
  return Yup.object(initialCorporateTaxSchema);
};

export const helperTextTypeToLabelMap = {
  whyDoWeNeedThis: "Why do we need this?",
  whatIsThis: "What is this?",
  whereToFindThis: "Where to find this?",
};
