import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Alert,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { lineItemsGroupedByFrequencyType } from "./products.type";
import {
  getDepositAmount,
  getGroupedByFrequencyTextPrices,
  getGroupedByFrequencyTotalPrices,
  getLineItemsFormatedForTheOrder,
  getTermsOfServiceUrl,
} from "./utils";
import PurchasedLineItemByFrequencyCard from "./PurchasedLineItemByFrequencyCard";
import TotalSummaryOfChargesRows from "./TotalSummaryOfChargesRows";
import StripeWrapper from "../../stripe/StripeWrapper";
import {
  ANNUAL,
  CANCELLED_WITH_NO_PENALTY_TEXT,
  MONTHLY,
  ONE_TIME,
  QUARTERLY,
} from "./constants";
import { getProductOrders } from "../../products/products.api";
import { AllOrdersAPIData, LineItemAPIData } from "../product/product.type";
import { convertNumberToCurrency } from "../../../utils/utils";
import { getDepositPaid } from "../../payment/payment.api";

const OrderProductsSection = () => {
  const [groupedOrderLineItems, setgroupedOrderLineItems] = useState<lineItemsGroupedByFrequencyType>();
  const [allOderLineItems, setAllgroupedOrderLineItems] = useState<LineItemAPIData[]>([]);

  useQuery(
    ["productOpenOrder"],
    getProductOrders,
    {
      onSuccess: (data: AllOrdersAPIData) => {
        setAllgroupedOrderLineItems(data?.openOrder?.lineItems || []);
        setgroupedOrderLineItems(getLineItemsFormatedForTheOrder(data?.openOrder?.lineItems || []));
      },
      refetchOnWindowFocus: false,
    },
  );
  
  const { data: depositPaid, isLoading: depositPaidIsLoading}
   = useQuery(
    ["depositPaid"],
    getDepositPaid,
    {
      refetchOnWindowFocus: false,
    },
  );

  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  // const [extraRequirementChecked, setExtraRequirementChecked] = useState<boolean>(false);

  const annualTotals = getGroupedByFrequencyTotalPrices(groupedOrderLineItems?.annuallyLineItems || []);
  const monthlyTotals = getGroupedByFrequencyTotalPrices(groupedOrderLineItems?.monthlyLineItems || []);
  const quarterlyTotals = getGroupedByFrequencyTotalPrices(groupedOrderLineItems?.quarterlyLineItems || []);
  const oneTimeTotals = getGroupedByFrequencyTotalPrices(groupedOrderLineItems?.oneTimeLineItems || []);
  const orderTotalPrice = (
    annualTotals.totalFinalPrice + monthlyTotals.totalFinalPrice
    + quarterlyTotals.totalFinalPrice + oneTimeTotals.totalFinalPrice
  );

  const annualTextArr = getGroupedByFrequencyTextPrices(groupedOrderLineItems?.annuallyLineItems || []);
  const monthlyTextArr = getGroupedByFrequencyTextPrices(groupedOrderLineItems?.monthlyLineItems || []);
  const quarterlyTextArr = getGroupedByFrequencyTextPrices(groupedOrderLineItems?.quarterlyLineItems || []);
  const oneTimeTextArr = getGroupedByFrequencyTextPrices(groupedOrderLineItems?.oneTimeLineItems || []);

  const depositAmount = getDepositAmount(allOderLineItems, orderTotalPrice);
  const TermsAndConditionsCheckBox = (
    <Form.Check
      className="pt-3"
      type="checkbox"
      label={
        <span>
          I've read and accept{" "}
          <Link
            to={getTermsOfServiceUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Fondo terms and conditions.
          </Link>
        </span>
      }
      disabled={!allOderLineItems.length}
      checked={termsChecked}
      onChange={(event) => setTermsChecked(event.target.checked)}
      id="termsandconditions"
    />
  );

  // const ExtraRequirementCheckbox = (
  //   <Form.Check
  //     className="pt-3"
  //     type="checkbox"
  //     label={
  //       <span>
  //         I understand that Fondo will file a Tax Extension for me and my
  //         &nbsp;corporate tax return will be filed after April 15th
  //       </span>
  //     }
  //     disabled={!allOderLineItems.length}
  //     checked={extraRequirementChecked}
  //     onChange={(event) => setExtraRequirementChecked(event.target.checked)}
  //     id="extraRequirementChecked"
  //   />
  // );

  return (
    <>
      <PurchasedLineItemByFrequencyCard
        frequency={ANNUAL}
        lineItems={groupedOrderLineItems?.annuallyLineItems || []}
        recurring={true}
        totalActualPrice={annualTotals.totalActualPrice}
        totalFinalPrice={annualTotals.totalFinalPrice}
        totalTextArr={annualTextArr}
        />
      {!!groupedOrderLineItems?.monthlyLineItems.length && (
        <PurchasedLineItemByFrequencyCard
          frequency={MONTHLY}
          lineItems={groupedOrderLineItems?.monthlyLineItems}
          recurring={true}
          totalActualPrice={monthlyTotals.totalActualPrice}
          totalFinalPrice={monthlyTotals.totalFinalPrice}
          totalTextArr={monthlyTextArr}
        />
      )}
      {!!groupedOrderLineItems?.quarterlyLineItems.length && (
        <PurchasedLineItemByFrequencyCard
          frequency={QUARTERLY}
          lineItems={groupedOrderLineItems?.quarterlyLineItems}
          recurring={true}
          totalActualPrice={quarterlyTotals.totalActualPrice}
          totalFinalPrice={quarterlyTotals.totalFinalPrice}
          totalTextArr={quarterlyTextArr}
        />
      )}
      {!!groupedOrderLineItems?.oneTimeLineItems.length && (
        <PurchasedLineItemByFrequencyCard
          frequency={ONE_TIME}
          lineItems={groupedOrderLineItems?.oneTimeLineItems}
          recurring={false}
          totalActualPrice={oneTimeTotals.totalActualPrice}
          totalFinalPrice={oneTimeTotals.totalFinalPrice}
          totalTextArr={oneTimeTextArr}
          />
      )}
      <div className="mb-4">
        <h4 className="py-1">Total Summary of Changes</h4>
        <Row className="justify-content-between mt-3">
          <Col className="d-flex justify-content-start">
            <span className="subtext-small text-grey text-uppercase">
              Frequency
            </span>
          </Col>
          <Col className="d-flex justify-content-end">
            <span className="subtext-small text-grey text-uppercase">
              Estimate
            </span>
          </Col>
        </Row>
        <hr className="my-2" />
        {!!groupedOrderLineItems?.oneTimeLineItems.length && (
          <>
            <TotalSummaryOfChargesRows
              name="Total One-Time"
              totalFinalPrice={oneTimeTotals.totalFinalPrice}
              textBelowName={CANCELLED_WITH_NO_PENALTY_TEXT}
              textBelowFinalPrice={oneTimeTextArr}
            />
            <hr style={ { borderBlockColor: "#EFF2F7" } }/>
          </>
        )}
        {!!groupedOrderLineItems?.annuallyLineItems.length && (
          <>
            <TotalSummaryOfChargesRows
              name="Total Annually"
              totalFinalPrice={annualTotals.totalFinalPrice}
              textBelowName={CANCELLED_WITH_NO_PENALTY_TEXT}
              textBelowFinalPrice={annualTextArr}
            />
            <hr style={ { borderBlockColor: "#EFF2F7" } }/>
          </>
        )}
        {!!groupedOrderLineItems?.monthlyLineItems.length && (
          <>
            <TotalSummaryOfChargesRows
              name="Total Monthly"
              totalFinalPrice={monthlyTotals.totalFinalPrice}
              textBelowName={CANCELLED_WITH_NO_PENALTY_TEXT}
              textBelowFinalPrice={monthlyTextArr}
            />
            <hr style={ { borderBlockColor: "#EFF2F7" } }/>
          </>
        )}
        {!!groupedOrderLineItems?.quarterlyLineItems.length && (
          <>
            <TotalSummaryOfChargesRows
              name="Total Quarterly"
              totalFinalPrice={quarterlyTotals.totalFinalPrice}
              textBelowName={CANCELLED_WITH_NO_PENALTY_TEXT}
              textBelowFinalPrice={quarterlyTextArr}
            />
          </>
        )}
        <Alert variant="secondary" className="py-3 px-4 m-0 align-items-center m-0">
          <Row>
            <Col className="p-0 text-start">DUE TODAY</Col>
            <Col className="p-0 text-end">{`$${convertNumberToCurrency(depositAmount)}`}</Col>
          </Row>
        </Alert>
      </div>
      {!allOderLineItems.length ? (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="termsandconditions">
                Please select a product!
              </Tooltip>
            }
          >
            <span>
              {TermsAndConditionsCheckBox}
              {/* {ExtraRequirementCheckbox} */}
            </span>
          </OverlayTrigger>
        </>
      ) : (
        <>
          {TermsAndConditionsCheckBox}
          {/* {ExtraRequirementCheckbox} */}
        </>
      )}
      <Row className="my-2">
        <Col>
          {!depositPaidIsLoading && !depositPaid?.hasPaidDeposit && (
            <StripeWrapper
              acceptedTermsAndConditions={
                termsChecked && !!allOderLineItems.length
              }
              depositAmountStripe={depositAmount * 100}
            >
            </StripeWrapper>
          )}
          <Link
            to="https://www.tryfondo.com/overview-call"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="btn btn-md btn-secondary full-width mt-3">
              Schedule a Call
            </Button>
          </Link>
        </Col>
      </Row>
      <small className="text-grey mt-3 mb-6">
        Depending on your situation, your company may require additional add-
        ons -- please find add-on pricing&nbsp;
        <Link
          to="https://www.tryfondo.com/add-on-pricing"
          target="_blank"
          rel="noopener noreferrer"
        >here</Link>.
      </small>
    </>
  );
};

export default OrderProductsSection;
