import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getActionItemsGeneralInfo, getActionItemsUserServices } from "./actionItems.api";
import FondoActionItemsOnboarding from "./FondoActionItemsOnboarding";
import { ActionItemsData } from "./actionItems.type";
import FondoActionItemsPostOnboarding from "./FondoActionItemsPostOnboarding";
import Bento from "../bento/Bento";
import ActionItemsCompletedView from "./ActionItemsCompletedView";
import ActionItemsNoDepositView from "./ActionItemsNoDepositView";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";
import ActionItemsPendingDepositView from "./ActionItemsPendingDepositView";

export default function ActionItems() {
  const companyLevelSettings = useContext(CompanyLevelSettingsContext);
  const isActionItemOnboardingEnabled = companyLevelSettings.actionItemsFeatureFlag;

  const { data: actionItemsData } = useQuery<ActionItemsData, Error>(
    ["actionItemsData"],
    () => getActionItemsGeneralInfo(),
    { enabled: isActionItemOnboardingEnabled },
  );

  const {
    data: actionItemsUserServicesData,
    isLoading: actionItemsUserServicesDataIsLoading,
  } = useQuery<any, Error>(
    ["actionItemsUserServices"],
    () => getActionItemsUserServices(),
    { enabled: isActionItemOnboardingEnabled },
  );

  if (!isActionItemOnboardingEnabled) {
    return <Bento />;
  }

  if (actionItemsData && !actionItemsData?.depositStatus) {
    return <ActionItemsNoDepositView />;
  }
  if (actionItemsData && actionItemsData.depositStatus === "pending") {
    return <ActionItemsPendingDepositView />;
  }
  if (actionItemsData && actionItemsData?.numberOfPendingActionItems === 0) {
    return <ActionItemsCompletedView />;
  }

  return (
    <>
      {actionItemsData && (
        <>
          {actionItemsData?.onboardingStatus === true ? (
            <FondoActionItemsOnboarding
              actionItemsData={actionItemsData}
              actionItemsUserServicesData={actionItemsUserServicesData}
              actionItemsUserServicesDataIsLoading={actionItemsUserServicesDataIsLoading}
            />
          ) : (
            <FondoActionItemsPostOnboarding />
          )}
        </>
      )}
    </>
  );
}
