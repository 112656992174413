import { createBrowserRouter, Navigate } from "react-router-dom";
import {
  ACTION_ITEMS,
  CORP_TAX_ONBOARDING,
  CUSTOMER_ORDER,
  DASHBOARD,
  DOCUMENTS,
  FOREIGN_SUBSIDIARY,
  FRANCHISE_TAX,
  FRANCHISE_TAX_COMPANIES,
  FRANCHISE_TAX_FILING_INFO,
  FRANCHISE_TAX_ONBOARDING_STATUS,
  INTEGRATIONS,
  PAYMENT,
  PRODUCTS,
  RESET_PASSWORD,
  RND,
  RUNWAY,
  SETTINGS,
  TAX_DEADLINES,
  YC_ONBOARDING_PRODUCTS,
  DEL_FRANCHISE_TAX,
  FILINGS_1099,
  FINANCE_DASHBOARD,
  CONNECT_PAYROLL,
  CONNECT_PAYROLL_MANUALLY,
  DROPBOX_SIGN,
  TAX_PLAN_DATA_FORM,
  REPORTING,
  REPORTING_DETAIL,
  REPORT_PDF,
  PASSPORT_PARTNERS,
  BANKING_INTEGRATION_TOKEN_CALLBACK,
} from "../constants/network-calls";
import ErrorPage from "./error/ErrorPage";
import Dashboard from "./dashboard/Dashboard";
import Root from "./root/Root";
import Login from "./login/Login";
import ActionItems from "./action-items/ActionItems";
import ActionItemServiceView from "./action-items/ActionItemServiceView";
import DocumentCenterParent from "./documents/DocumentCenterParent";
import Integrations from "./integrations/Integrations";
import TaxDeadlines from "./tax-deadlines/TaxDeadlines";
import Settings from "./settings/Settings";
import Protected from "./protected/Protected";
import Filings1099 from "./filings-1099/Filings1099";
import Signup from "./onboarding/signup/Signup";
import PasswordReset from "./password-reset/PasswordReset";
import IframeWrapper from "./iframe-wrapper/IframeWrapper";
import Onboarding from "./onboarding/Onboarding";
import Company1 from "./onboarding/company/Company1";
import Company2 from "./onboarding/company/Company2";
import Activity1 from "./onboarding/activity/Activity1";
import Activity2 from "./onboarding/activity/Activity2";
import PasswordResetConfirm from "./password-reset-confirm/PasswordResetConfirm";
import Product from "./onboarding/product/Product";
import OnboardingSuccess from "./onboarding/OnboardingSuccess";
import RnDForm from "./rd-credit/post-2023/RnDForm";
import CorporateTaxRouter from "./corporate-tax/CorporateTaxRouter";
import DashboardOnboarding from "./dashboard-onboarding/DashboardOnboarding";
import DashboardOnboardingSignup from "./dashboard-onboarding/signup/Signup";
import Company from "./dashboard-onboarding/company/Company";
import Connections from "./dashboard-onboarding/connections/Connections";
import Runway from "./runway/Runway";
import FinanceDashboard from "./finance-dashboard/FinanceDashboard";
import TaxPlan from "./onboarding/tax-plan/TaxPlan";
import PayrollConnection from "./finance-dashboard/tax-plan/PayrollConnection";
import PayrollManualConnection from "./finance-dashboard/tax-plan/PayrollManualConnection";
import NonDeCCorpView from "./onboarding/NonDeCCorpView";
import ForeignSubsidiary from "./foreign-subsidiary/ForeignSubsidiary";
import ForeignSubsidiaryForm from "./foreign-subsidiary/ForeignSubsidiaryForm";
import Checkout from "./onboarding/checkout/Checkout";
import ProductParentPage from "./products/ProductParentPage";
import DropboxSign from "./documents/DropboxSign";
import DelawareFranchiseTaxRouter from "./delaware-franchise-tax/DelawareFranchiseTaxRouter";
import Reporting from "./reporting/Reporting";
import ReportingDetailAndEdit from "./reporting/ReportingDetailAndEdit/ReportingDetailAndEdit";
import { PassportPartners } from "./passport-partners-marketplace/partners/PassportPartners";
import { PassportPartnersCompanyReviews } from "./passport-partners-marketplace/reviews/PassportPartnersCompanyReviews";
import BankingIntegrationCallback from "./banking-integration/BankingIntegrationCallback";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Protected>
        <Root />
      </Protected>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "accounts/login",
        element: <Login />,
      },
      {
        path: "onboarding",
        element: <Onboarding />,
        children: [
          {
            path: "signup",
            element: <Signup />,
          },
          {
            path: "company1",
            element: <Company1 />,
          },
          {
            path: "company2",
            element: <Company2 />,
          },
          {
            path: "activity1",
            element: <Activity1 />,
          },
          {
            path: "activity2",
            element: <Activity2 />,
          },
          {
            path: "tax-plan",
            element: <TaxPlan />,
          },
          {
            path: "product",
            element: <Product />,
          },
          {
            path: "checkout",
            element: <Checkout />,
          },
          {
            path: "onboarding-success",
            element: <OnboardingSuccess />,
          },
          {
            path: "non-delaware-c-corp",
            element: <NonDeCCorpView />,
          },
        ],
      },
      {
        path: "dashboard-onboarding/",
        element: <DashboardOnboarding />,
        children: [
          {
            path: "user",
            element: <DashboardOnboardingSignup />,
          },
          {
            path: "company",
            element: <Company />,
          },
          {
            path: "connections",
            element: <Connections />,
          },
        ],
      },
      {
        path: DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: ACTION_ITEMS,
        element: <ActionItems />,
      },
      {
        path: `${ACTION_ITEMS}:actionItemServiceId`,
        element: <ActionItemServiceView />,
      },
      {
        path: `${CORP_TAX_ONBOARDING}:taxYear`,
        element: <CorporateTaxRouter />,
      },
      {
        path: `${DEL_FRANCHISE_TAX}:taxYear`,
        element: <DelawareFranchiseTaxRouter />,
      },
      {
        path: `${FILINGS_1099}:taxYear`,
        element: <Filings1099 />,
      },
      {
        path: `${FOREIGN_SUBSIDIARY}:taxYear`,
        element: <ForeignSubsidiary />,
      },
      {
        path: `${FOREIGN_SUBSIDIARY}:taxYear/:uuid`,
        element: <ForeignSubsidiaryForm />,
      },
      {
        path: DOCUMENTS,
        element: <DocumentCenterParent />,
      },
      {
        path: REPORTING,
        element: <Reporting />,
      },
      {
        path: REPORTING_DETAIL,
        element: <ReportingDetailAndEdit />,
      },
      {
        path: `${PRODUCTS}*`,
        element: <ProductParentPage />,
      },
      {
        path: INTEGRATIONS,
        element: <Integrations />,
      },
      {
        path: `${RUNWAY}*`,
        element: <Runway />,
      },
      {
        path: `${FINANCE_DASHBOARD}*`,
        element: <FinanceDashboard />,
      },
      {
        path: TAX_DEADLINES,
        element: <TaxDeadlines />,
      },
      {
        path: `${RND}:formName`,
        element: <IframeWrapper />,
      },
      {
        path: `${RND}r_and_d_2023`,
        element: <RnDForm />,
      },
      {
        path: CUSTOMER_ORDER,
        element: <IframeWrapper />,
      },
      {
        path: `${SETTINGS}*`,
        element: <Settings />,
      },
      {
        path: YC_ONBOARDING_PRODUCTS,
        element: <Navigate to={"/onboarding/product"} replace={true} />,
      },
      {
        path: FRANCHISE_TAX,
        element: <IframeWrapper />,
        children: [
          {
            path: FRANCHISE_TAX_COMPANIES,
            element: <IframeWrapper />,
          },
          {
            path: FRANCHISE_TAX_FILING_INFO,
            element: <IframeWrapper />,
          },
          {
            path: FRANCHISE_TAX_ONBOARDING_STATUS,
            element: <IframeWrapper />,
          },
        ],
      },
      {
        path: PAYMENT,
        element: <Navigate to={"/onboarding/product"} replace={true} />,
      },
      {
        path: "accounts/password/reset/confirm/:uid/:token/",
        element: <PasswordResetConfirm />,
      },
      {
        path: RESET_PASSWORD,
        element: <PasswordReset />,
      },
      {
        path: CONNECT_PAYROLL,
        element: <PayrollConnection />,
      },
      {
        path: `${CONNECT_PAYROLL_MANUALLY}:taxYear`,
        element: <PayrollManualConnection />,
      },
      {
        path: TAX_PLAN_DATA_FORM,
        element: <PayrollManualConnection />,
      },
      {
        path: DROPBOX_SIGN,
        element: <DropboxSign />,
      },
      {
        path: "marketplace/",
        element: <Navigate to={"/marketplace/partners"} replace={true} />,
      },
      {
        path: `${PASSPORT_PARTNERS}/*`,
        element: <PassportPartners />,
      },
      {
        path: "marketplace/reviews/",
        element: <PassportPartnersCompanyReviews />,
      },
      {
        path: `${BANKING_INTEGRATION_TOKEN_CALLBACK}`,
        element: <BankingIntegrationCallback />,
      }
    ],
  },
  {
    path: REPORT_PDF,
    element: (
      <Protected>
        <ReportingDetailAndEdit />
      </Protected>
    ),
  },
]);
