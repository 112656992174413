import { useFormikContext } from "formik";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import {
  Post2023CorpTaxFormFields,
  post2023CompanyInfoFieldToLabelMap,
  post2023CompanyInfoFieldToTypeMap,
} from "./post2023CorporateTax.type";

export default function Post2023CompanyInfo() {
  const formikContext = useFormikContext<Post2023CorpTaxFormFields>();

  return (<>
    <div>
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="companyInfo.conversion"
          labels={post2023CompanyInfoFieldToLabelMap()}
          types={post2023CompanyInfoFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="companyInfo.hasFiled1099"
          labels={post2023CompanyInfoFieldToLabelMap()}
          types={post2023CompanyInfoFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="companyInfo.hasSubsidiaries"
          labels={post2023CompanyInfoFieldToLabelMap()}
          types={post2023CompanyInfoFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="companyInfo.hasForeignSubsidiaries"
          labels={post2023CompanyInfoFieldToLabelMap()}
          types={post2023CompanyInfoFieldToTypeMap}
        />
        {formikContext.values.companyInfo?.hasForeignSubsidiaries === "True" && (
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="companyInfo.numberOfForeignSubsidiaries"
            labels={post2023CompanyInfoFieldToLabelMap()}
            types={post2023CompanyInfoFieldToTypeMap}
          />)}
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="companyInfo.hasForeignAccounts"
          labels={post2023CompanyInfoFieldToLabelMap()}
          types={post2023CompanyInfoFieldToTypeMap}
        />
        {formikContext.values.companyInfo?.hasForeignAccounts === "True" && (
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="companyInfo.numberOfForeignAccounts"
            labels={post2023CompanyInfoFieldToLabelMap()}
            types={post2023CompanyInfoFieldToTypeMap}
          />)}
    </div>
  </>);
}
