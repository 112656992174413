import {
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { ReactNode } from "react";
import { getTooltipPopover } from "./utils";

type CorporateTaxSummaryCardProps = {
  title: string;
  value: string|number;
  valueColorClassName?: "primary"|"danger"|"success";
  icon: string;
  disabled?: boolean;
  disabledIcon?: string;
  isLoading?: boolean;
  showHelpTooltip?: boolean;
  helpTooltipHTML?: ReactNode;
}

const CorporateTaxSummaryCard = ({
  title,
  value,
  valueColorClassName = "primary",
  icon,
  disabled = false,
  disabledIcon,
  isLoading = false,
  showHelpTooltip = false,
  helpTooltipHTML = "",
}: CorporateTaxSummaryCardProps) => {
  const renderHelpTooltip = () => {
    if (showHelpTooltip && helpTooltipHTML) {
      return getTooltipPopover(helpTooltipHTML);
    }
  };

  if (isLoading) {
    return (
      <Card className="rounded">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-uppercase text-muted mb-2">
                {title}
              </h6>
              <Spinner
                variant="primary"
                animation="border"
                role="status"
                className="mt-1"
              />
            </Col>
            <Col className="col-auto">
              <div>
                <img src={disabled && disabledIcon ? disabledIcon : icon} />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="rounded">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">
              {title}
            </h6>
            {disabled ? (
              <small className="text-muted">
                Enter your TaxPlan data to enable this feature
              </small>
            ) : (
              <span className={`h2 mb-0 text-${valueColorClassName}`}>
                ~{value}
                {renderHelpTooltip()}
              </span>
            )}
          </Col>
          <Col className="col-auto">
            <div>
              <img src={disabled && disabledIcon ? disabledIcon : icon} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CorporateTaxSummaryCard;
