import {
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import Cleave from "cleave.js/react";
import { Option } from "react-bootstrap-typeahead/types/types";
import { Subdivision } from "../../../utils/util.type";
import { TaxPlanResult } from "../financeDashboard.type";
import { getTaxPlanResultData, saveManualPayrollData } from "../FinanceDashboard.api";
import { FINANCE_DASHBOARD_TAX_PLAN } from "../../../constants/network-calls";
import { getCountrySubdivisions } from "../../../utils/util.api";

type PayrollManualConnectionFormValues = {
  taxYear: string;
  states: Subdivision[];
  income: number|string;
  expenses: number|string;
  assets: number|string;
  totalEstimatedUsPdRelatedPayroll: number|string;
  totalEstimatedInternationalPdRelatedPayroll: number|string;
  numberOfForeignBankAccounts: number;
  numberOfForeignShareholders: number;
  numberOfInternationalSubsidiaries: number;

}

const PayrollManualConnectionForm = (
  {taxYear}: {taxYear: string}
) => {

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: taxPlanResultData, isLoading: taxPlanResultDataIsLoading } = useQuery<TaxPlanResult, Error>(
    ["taxPlanResultData"],
    () => getTaxPlanResultData(taxYear),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: countrySubdivisionQuery, isLoading: countrySubdivisionQueryIsLoading } = useQuery<Subdivision[], Error>(
    ["countrySubdivision", "US"],
    getCountrySubdivisions,
    {
      refetchOnWindowFocus: false,
    },
  );

  const [formValues, setFormValues] = useState<PayrollManualConnectionFormValues>({
    taxYear,
    states: [],
    totalEstimatedUsPdRelatedPayroll: "",
    totalEstimatedInternationalPdRelatedPayroll: "",
    income: "",
    expenses: "",
    assets: "",
    numberOfForeignBankAccounts: 0,
    numberOfForeignShareholders: 0,
    numberOfInternationalSubsidiaries: 0,
  });

  useEffect(() => {
    if (taxPlanResultData) {
      setFormValues({
        taxYear,
        states: taxPlanResultData.states,
        totalEstimatedUsPdRelatedPayroll: taxPlanResultData.totalEstimatedUsPdRelatedPayroll || "",
        totalEstimatedInternationalPdRelatedPayroll:
          taxPlanResultData.totalEstimatedInternationalPdRelatedPayroll || "",
        numberOfForeignBankAccounts: taxPlanResultData.numberOfForeignBankAccounts || 0,
        numberOfForeignShareholders: taxPlanResultData.numberOfForeignShareholders || 0,
        numberOfInternationalSubsidiaries: taxPlanResultData.numberOfInternationalSubsidiaries || 0,
        income: taxPlanResultData.income || "",
        expenses: taxPlanResultData.expenses || "",
        assets: taxPlanResultData.assets || "",
      });
    }
  }, [taxPlanResultData]);

  const handleFormChange = (
    property: string,
    value: string|null,
  ) => {
    const cleanedValue = value?.replaceAll(",", "");
    setFormValues((prevState) => ({
      ...prevState,
      [property]: Number(cleanedValue),
    }));
  };

  const handleStateChange = (states: Option[]) => {
    setFormValues((prevState) => ({
      ...prevState,
      states: states as Subdivision[],
    }));
  };

  const disableSubmitButton = () => {
    if (
      formValues.totalEstimatedUsPdRelatedPayroll !== ""
      && formValues.totalEstimatedInternationalPdRelatedPayroll !== ""
      && formValues.assets !== ""
      && formValues.expenses !== ""
      && formValues.income !== ""
      && formValues.states.length > 0
    ) {
      return false;
    }
    return true;
  };

  const formValuesToProperPayload = (values: PayrollManualConnectionFormValues) => {
    // Turns the form values to the type we need to submit.
    // (States array should be an array of states ids)
    const properPayload = {
      ...formValues,
      states: values.states.map((subdivision) => subdivision.id),
    };
    return properPayload;
  };

  const saveManuallyInputPayrollMutation = useMutation<any, AxiosError<any>>(
    () => saveManualPayrollData(formValuesToProperPayload(formValues)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["taxPlanResultData"],
        });
        queryClient.invalidateQueries({
          queryKey: ["companyFilingsDeadlines"],
        });
        navigate(FINANCE_DASHBOARD_TAX_PLAN);
      },
    },
  );

  if (taxPlanResultDataIsLoading) {
    return (
      <div className="row justify-content-center mt-5 p-5">
        <Spinner variant="primary" animation="border" role="status" />
      </div>
    );
  }

  return (
    <>
      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="totalEstimatedUsPdRelatedPayroll"
          >
            Total Estimated {formValues.taxYear} Whole Year US Payroll -
            W2 & contractors - related to product development (Engineers, Designers, Product Managers, etc.)
          </label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text input-side-box">$</span>
            </div>
            <Cleave
              options={{ numeral: true }}
              className="form-control"
              value={formValues.totalEstimatedUsPdRelatedPayroll}
              onChange={(e) => handleFormChange(
                "totalEstimatedUsPdRelatedPayroll",
                e.target.value,
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="totalEstimatedInternationalPdRelatedPayroll"
          >
            Total Estimated {formValues.taxYear} Whole Year International Payroll -
            contractors - related to product development (Engineers, Designers, Product Managers, etc.)
          </label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text input-side-box">$</span>
            </div>
            <Cleave
              options={{ numeral: true }}
              className="form-control"
              value={formValues.totalEstimatedInternationalPdRelatedPayroll}
              onChange={(e) => handleFormChange(
                "totalEstimatedInternationalPdRelatedPayroll",
                e.target.value,
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="income"
          >
            Total Estimated {formValues?.taxYear} Whole Year Revenue
          </label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text input-side-box">$</span>
            </div>
            <Cleave
              options={{ numeral: true }}
              className="form-control"
              value={formValues.income}
              onChange={(e) => handleFormChange(
                "income",
                e.target.value,
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="expenses"
          >
            Total Estimated {formValues?.taxYear} Whole Year Expenses
          </label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text input-side-box">$</span>
            </div>
            <Cleave
              options={{ numeral: true }}
              className="form-control"
              value={formValues.expenses}
              onChange={(e) => handleFormChange(
                "expenses",
                e.target.value,
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="assets"
          >
            Total Estimated {formValues?.taxYear} Assets (How much funding have you raised?)
          </label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text input-side-box">$</span>
            </div>
            <Cleave
              options={{ numeral: true }}
              className="form-control"
              value={formValues.assets}
              onChange={(e) => handleFormChange(
                "assets",
                e.target.value,
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="numberOfForeignBankAccounts"
          >
            How many foreign accounts with $10,000 or more do you have in {formValues.taxYear}?
          </label>
          <Form.Control
            type="number"
            value={formValues.numberOfForeignBankAccounts}
            onChange={(e) => handleFormChange(
              "numberOfForeignBankAccounts",
              e.target.value,
            )}
          />
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="numberOfForeignShareholders"
          >
            How many foreign shareholders that own more than 25% do you have in {formValues.taxYear}?
          </label>
          <Form.Control
            type="number"
            value={formValues.numberOfForeignShareholders}
            onChange={(e) => handleFormChange(
              "numberOfForeignShareholders",
              e.target.value,
            )}
          />
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label
            htmlFor="numberOfForeignSubsidiaries"
          >
            How many international subsidiaries do you have in {formValues.taxYear}?
          </label>
          <Form.Control
            type="number"
            value={formValues.numberOfInternationalSubsidiaries}
            onChange={(e) => handleFormChange(
              "numberOfInternationalSubsidiaries",
              e.target.value,
            )}
          />
        </div>
      </div>

      <div className="row mb-3 mt-4">
        <div className="col-12 col-md-12">
          <label htmlFor="statesWithOffices">
            Which states did the company have founders,
            W2 employees, or an office in during {formValues.taxYear}?
          </label>
          <Typeahead
            id="us-states-selector"
            isLoading={countrySubdivisionQueryIsLoading}
            options={countrySubdivisionQuery || []}
            placeholder='Search for State'
            selected={formValues.states}
            labelKey="name"
            multiple={true}
            onChange={(selected) => handleStateChange(selected)}
          />
        </div>
      </div>

      <hr className="mt-5" />
      <div className="row mb-5 mt-2">
        <div className="d-flex flex-row col-12 col-md-12 justify-content-between mt-3">
          <Link to="/dashboard/finance/tax-plan/">
            <Button variant="white" className="btn btn-lg">
              Cancel
            </Button>
          </Link>
          <Button
            onClick={() => saveManuallyInputPayrollMutation.mutate()}
            className="btn btn-lg"
            disabled={disableSubmitButton()}
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
};

export default PayrollManualConnectionForm;
