import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import FondoLayout from "../../fusion-kit/FondoLayout";
import { getPartners } from "../passport-partners.api";
import Spinner from "../../spinner/Spinner";
import { PartnerCard } from "./PartnerCard";
import { ConnectionType } from "../../asyncSelectors/AsyncExternalConnectionTypeSelect";
import { Partner } from "../passport-partners.types";
import { FeaturedPartnerBanner } from "./FeaturedPartnerBanner";
import { getApiClient } from "../../../utils/util.api";
import { PartnerFilterBar } from "./PartnerFilterBar";

const PartnersLayout = ({ children }: { children: JSX.Element }) => (
  <FondoLayout title="Marketplace" pretitle="Welcome" navBottomMargin={3}>
    {children}
  </FondoLayout>
);

export const PassportPartners = () => {
  const [partnersToDisplay, setPartnersToDisplay] = useState<Partner[]>([]);
  const connectionTypesQuery = useQuery(
    ["externalConnectionTypes"],
    async () => {
      const apiClient = await getApiClient();
      const response = await apiClient.listReactifyExternalConnectionsConnectionTypes();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
  const partnersQuery = useQuery(["passportPartnersPartners"], () => getPartners(), {
    refetchOnWindowFocus: false,
    // partners are unlikely to change during the session
    staleTime: Infinity,
  });

  useEffect(() => {
    if (partnersQuery.data) {
      setPartnersToDisplay(partnersQuery.data);
    }
  }, [partnersQuery.data]);

  const getFeaturedPartnerBanner = (partnerArr: Partner[]) => {
    const featuredPartner = partnerArr.find((partner) => partner.isFeatured);

    if (featuredPartner) {
      return <FeaturedPartnerBanner partner={featuredPartner} />;
    }

    return null;
  };

  let partnersContent = <></>;

  if ([partnersQuery, connectionTypesQuery].some((query) => query.isLoading || query.data === undefined)) {
    partnersContent = <Spinner />;
  } else if (partnersQuery.data && connectionTypesQuery.data) {
    const idToConnectionTypesMap = connectionTypesQuery.data.reduce((acc, ct) => {
      acc[ct.id] = ct;
      return acc;
    }, {} as Record<number, ConnectionType>);

    if (!partnersToDisplay.length) {
      partnersContent = (
        <Alert variant="" style={{ background: "#EDF2F9", color: "gray" }} className="w-100 text-center">
          No results found. Try using fewer filters for more results
        </Alert>
      );
    } else {
      partnersContent = (
        <>
          {getFeaturedPartnerBanner(partnersToDisplay)}
          <Container fluid>
            <Row>
              {partnersToDisplay.map((partner) => (
                <Col md={12} lg={6} xl={4} className="mb-4" key={partner.uuid}>
                  <PartnerCard partner={partner} idToConnectionTypesMap={idToConnectionTypesMap} />
                </Col>
              ))}
            </Row>
          </Container>
        </>
      );
    }
    partnersContent = (
      <>
        <PartnerFilterBar
          partners={partnersQuery.data ?? []}
          setPartnersToDisplay={setPartnersToDisplay}
          idToConnectionTypesMap={idToConnectionTypesMap}
        />
        {partnersContent}
      </>
    );
  }

  return (
    <PartnersLayout>
      <div className="d-flex flex-column align-items-center">{partnersContent}</div>
    </PartnersLayout>
  );
};
