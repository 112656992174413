import { TaxPlanResult } from "../financeDashboard.type";

export const getRDCreditEstimateAmount = (taxPlanResultData?: TaxPlanResult) => {
  if (taxPlanResultData) {
    const totalEstimatedUsPayroll = taxPlanResultData?.totalEstimatedUsPdRelatedPayroll || 0;
    return totalEstimatedUsPayroll * 0.06;
  }
  return 0;
};

export const getNetIncomeEstimate = (taxPlanResultData?: TaxPlanResult) => {
  if (taxPlanResultData) {
    const totalEstimatedRevenue = taxPlanResultData.income || 0;
    const totalEstimatedExpenses = taxPlanResultData.expenses || 0;
    return totalEstimatedRevenue - totalEstimatedExpenses;
  }
  return 0;
};

export const getTaxableIncomeEstimate = (taxPlanResultData?: TaxPlanResult) => {
  if (taxPlanResultData) {
    const section174Estimate = taxPlanResultData.section174Addback;
    const netIncomeEstimate = getNetIncomeEstimate(taxPlanResultData);
    return section174Estimate + netIncomeEstimate;
  }
  return 0;
};
