import {
  COMPANY_FILINGS_DEADLINES,
  FINANCIAL_HUB_ACCOUNTS,
  GET_BALANCE_AVAILABLE_MONTHS,
  GET_BALANCE_MONTHS_FROM_FOUNDING_DATE,
  GET_BURN_RATE,
  GET_CURRENT_BALANCE,
  GET_HISTORICAL_COMPOUND_GROWTH_RATE,
  GET_HISTORICAL_FUNDING,
  GET_HISTORICAL_GROWTH_RATE,
  GET_HISTORICAL_PROFIT_AND_LOSS,
  GET_INSTITUTIONS,
  GET_INSTITUTION_CONNECTIONS,
  GET_INTERNATIONAL_ACTIVITY,
  GET_MONTHLY_BALANCES,
  GET_QBO_BALANCE_SHEET,
  GET_QBO_PNL_REPORT,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_PAGINATED,
  TAX_PLAN_RESULT,
  REFRESH_BALANCES,
} from "../../constants/network-calls";
import {
  BalancesPayloadToSubmit,
  BurnRateResponse,
  CompanyFilingsDeadlinesPaginatedResponse,
  CompoundGrowthRatePeriod,
  EndingBalancePayload,
  FinancialHubInstitution,
  FundingData,
  GrowthRatePeriod,
  InternationalActivity,
  TaxPlanResult,
  PayrollManualConnectionFormValuesToSubmit,
  ProfitAndLossPeriod,
  Transaction,
  TransactionCategory,
} from "./financeDashboard.type";
import { ProgressManagerStatus } from "../../common/type";
import { getFondoApi, patchFondoApi, postFondoApi } from "../../utils/util.api";
import { QBOReportingData } from "../reporting/Reporting.type";

export async function getBalanceAvailableMonths(): Promise<any> {
  const result = await getFondoApi(GET_BALANCE_AVAILABLE_MONTHS);
  return result;
}

export async function getMonthsFromFoundingDate(): Promise<any> {
  const result = await getFondoApi(GET_BALANCE_MONTHS_FROM_FOUNDING_DATE);
  return result;
}

export async function getMonthlyBalance(monthAndYear: string): Promise<any> {
  const result = await getFondoApi(
    GET_MONTHLY_BALANCES,
    { balanceAtStartMonth: monthAndYear, balanceAtEndMonth: monthAndYear },
  );
  return result;
}

export async function getCurrentBalance(): Promise<any> {
  const result = await getFondoApi(GET_CURRENT_BALANCE);
  return result;
}

export async function getHistoricalMonthlyBalances(monthAndYear: string, interval: string): Promise<any> {
  const result = await getFondoApi(
    GET_MONTHLY_BALANCES,
    {
      balanceAtEndMonth: monthAndYear,
      historical: true,
      interval,
    },
  );
  return result;
}

export async function getHistoricalBurnRate(monthAndYear: string, interval: string): Promise<BurnRateResponse> {
  const result = await getFondoApi(
    GET_BURN_RATE,
    {
      endMonth: monthAndYear,
      interval,
    },
  );
  return result as BurnRateResponse;
}

export async function getTransactions(): Promise<Transaction[]> {
  const result = await getFondoApi(GET_TRANSACTIONS);
  return result as Transaction[];
}

export async function getMoneyInTransactions(): Promise<Transaction[]> {
  const result = await getFondoApi(GET_TRANSACTIONS, { moneyIn: true });
  return result as Transaction[];
}

export async function updateTransactions(transactions: Transaction[]): Promise<any> {
  const result = patchFondoApi(`${GET_TRANSACTIONS}update_transactions/`, transactions);
  return result;
}

export async function getHistoricalProfitAndLoss(
  monthAndYear: string,
  interval: string,
): Promise<ProfitAndLossPeriod[]> {
  const result = await getFondoApi(
    GET_HISTORICAL_PROFIT_AND_LOSS,
    {
      untilDate: monthAndYear,
      interval,
    },
  );
  return result as ProfitAndLossPeriod[];
}

// export async function getHistoricalQboProfitAndLoss(monthAndYear: string, interval: string): Promise<any> {
//   let url = `${GET_HISTORICAL_QBO_PROFIT_AND_LOSS}?`;
//   url += `untilDate=${monthAndYear}`;
//   url += `&interval=${interval}`;
//   const result: AxiosResponse<ProfitAndLossPeriod[]> = await network.fetch("GET", url);
//   const data = humps.camelizeKeys(result.data);
//   return data;
// }

export async function getHistoricalGrowthRate(monthAndYear: string, interval: string): Promise<GrowthRatePeriod[]> {
  const result = await getFondoApi(
    GET_HISTORICAL_GROWTH_RATE,
    {
      untilDate: monthAndYear,
      interval,
    },
  );
  return result as GrowthRatePeriod[];
}

export async function getHistoricalCompoundGrowthRate(
  monthAndYear: string,
  interval: string,
): Promise<CompoundGrowthRatePeriod[]> {
  const result = await getFondoApi(
    GET_HISTORICAL_COMPOUND_GROWTH_RATE,
    {
      untilDate: monthAndYear,
      interval,
    },
  );
  return result as CompoundGrowthRatePeriod[];
}

export async function getHistoricalFunding(monthAndYear: string, interval: string): Promise<FundingData> {
  const result = await getFondoApi(
    GET_HISTORICAL_FUNDING,
    {
      untilDate: monthAndYear,
      interval,
    },
  );
  return result as FundingData;
}

export async function getQboBalanceSheetReport(monthAndYear: string): Promise<QBOReportingData> {
  const result = await getFondoApi(
    GET_QBO_BALANCE_SHEET,
    {
      until_date: monthAndYear,
    },
  );
  return result as QBOReportingData;
}

export async function getQboPnLReport(monthAndYear: string, interval: string): Promise<QBOReportingData> {
  const result = await getFondoApi(
    GET_QBO_PNL_REPORT,
    {
      until_date: monthAndYear,
      interval,
    },
  );
  return result as QBOReportingData;
}

export async function refreshBalances(): Promise<ProgressManagerStatus> {
  const result = await postFondoApi(REFRESH_BALANCES, {});
  return result as ProgressManagerStatus;
}

export async function getCompanyFilingsDeadlines(
  taxYear: string|number
  ): Promise<CompanyFilingsDeadlinesPaginatedResponse> {
  const result = await getFondoApi(COMPANY_FILINGS_DEADLINES, { taxYear });
  return result as CompanyFilingsDeadlinesPaginatedResponse;
}

export async function getInternationalActivity(taxYear: string|number): Promise<InternationalActivity> {
  const result = await getFondoApi(GET_INTERNATIONAL_ACTIVITY, { taxYear });
  return result as InternationalActivity;
}

export async function getTaxPlanResultData(taxYear: string|number): Promise<TaxPlanResult> {
  const result = await getFondoApi(TAX_PLAN_RESULT, { taxYear });
  return result as TaxPlanResult;
}

export async function saveManualPayrollData(payrollData: PayrollManualConnectionFormValuesToSubmit): Promise<any> {
  const result = await postFondoApi(TAX_PLAN_RESULT, payrollData);
  return result;
}

type requestPaginatedTransactionsListOptions = {
  pageSize?: number
  category?: string | null | undefined;
  maxAmount?: number | null;
  minAmount?: number | null;
  startDate?: string;
  endDate?: string;
  last30Days?: boolean;
  last6Months?: boolean;
  lastYear?: boolean;
  ordering?: string;
  currentSearch?: string;
}

export async function requestPaginatedTransactionsList(
  page: number,
  options?: requestPaginatedTransactionsListOptions,
): Promise<any> {
  // The nice thing about Axios params is that if a value is undefined or null,
  // it will not be added to the url parameters
  const params = {
    page,
    pageSize: options?.pageSize || 10,
    category: options?.category,
    minAmount: options?.minAmount,
    maxAmount: options?.maxAmount,
    startDate: options?.startDate,
    endDate: options?.endDate,
    last30Days: options?.last30Days,
    last6Months: options?.last6Months,
    lastYear: options?.lastYear,
    ordering: options?.ordering,
    search: options?.currentSearch,
  };
  const result = await getFondoApi(GET_TRANSACTIONS_PAGINATED, params);
  return result;
}

export async function updateTransactionAmount(
  transactionUuid: string,
  amount: string|number,
): Promise<any> {
  const result = await patchFondoApi(
    `${GET_TRANSACTIONS_PAGINATED}${transactionUuid}/update_transaction_amount/`,
    { manuallyEnteredAmount: amount },
  );
  return result;
}

type requestTransactionsStatsOptions = {
  maxAmount?: number | null;
  minAmount?: number | null;
  startDate?: string;
  endDate?: string;
  last30Days?: boolean;
  last6Months?: boolean;
  lastYear?: boolean;
  ordering?: string;
  currentSearch?: string;
}

export async function getTransactionsStats(options?: requestTransactionsStatsOptions): Promise<any> {
  const params = {
    minAmount: options?.minAmount,
    maxAmount: options?.maxAmount,
    startDate: options?.startDate,
    endDate: options?.endDate,
    last30Days: options?.last30Days,
    last6Months: options?.last6Months,
    lastYear: options?.lastYear,
  };
  const result = await getFondoApi(`${GET_TRANSACTIONS_PAGINATED}get_transactions_stats/`, params);
  return result;
}

export async function massUpdateTransactionCategory(
  transactionUuids: string[],
  category: TransactionCategory|undefined,
): Promise<any> {
  const result = await patchFondoApi(
    `${GET_TRANSACTIONS_PAGINATED}mass_update_transaction_category/`,
    { transactionUuids, category },
  );
  return result;
}

export async function updateTransactionCategory(
  transactionUuid: string,
  category: string|undefined|null,
): Promise<any> {
  const result = await patchFondoApi(
    `${GET_TRANSACTIONS_PAGINATED}${transactionUuid}/update_transaction_category/`,
    { category },
  );
  return result;
}

export async function updateEndingBalancesAudit(data: BalancesPayloadToSubmit): Promise<any> {
  const result = await postFondoApi(GET_MONTHLY_BALANCES, data);
  return result;
}

type RequestFinancialHubInstitutionOptions = {
  search?: string;
  companyId?: number;
};

type RequestFinancialHubInstitutionConnectionOptions = {
  search?: string;
  excludeManuallyAdded: boolean;
};

export async function requestFinancialHubInstitution(
  options: RequestFinancialHubInstitutionOptions,
): Promise<FinancialHubInstitution[]> {
  const params = {
    companyId: options?.companyId,
    search: options?.search,
  };
  const result = await getFondoApi(GET_INSTITUTIONS, params);
  return result as FinancialHubInstitution[];
}

export async function requestFinancialHubInstitutionConnections(
  options: RequestFinancialHubInstitutionConnectionOptions,
): Promise<FinancialHubInstitution[]> {
  const params = {
    search: options?.search,
    excludeManuallyAdded: options?.excludeManuallyAdded,
  };
  const result = await getFondoApi(GET_INSTITUTION_CONNECTIONS, params);
  return result as FinancialHubInstitution[];
}

export async function generateEndingBalances(
  data: EndingBalancePayload,
): Promise<any> {
  const { accountOpenedOn, endingBalances } = data;
  const result: any = await patchFondoApi(
    `${FINANCIAL_HUB_ACCOUNTS}/${data.accountUuid}/generate_ending_balances/`,
    {
      accountOpenedOn,
      endingBalances,
    },
    {},
  );
  return result;
}


export async function categorizeTransactionsUsingAI(
  transactionUuids: string[],
): Promise<any> {
  const result = await patchFondoApi(
    `${GET_TRANSACTIONS_PAGINATED}categorize_transactions_using_ai/`,
    { transactionUuids },
  );
  return result as ProgressManagerStatus;
}

export async function categorizeNonVerifiedTransactionUsingAI(): Promise<ProgressManagerStatus> {
  const result = await patchFondoApi(
    `${GET_TRANSACTIONS_PAGINATED}categorize_non_verified_transactions_using_ai/`,
    {}
  )
  ;
  return result as ProgressManagerStatus;
}